import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @import '~antd/dist/antd.css';

    #root:before  {
        content: " ";
        display: block;
        height: 5px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(/img/svg/before-header.jpg) left top repeat-y;
        background-size: 100%; 
        z-index: 99999;
      }
      
    @font-face {
        font-family: "Poppins Regular";
        src: url("/fonts/Poppins-Regular.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Poppins Medium";
        src: url("/fonts/Poppins-Medium.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Poppins SemiBold";
        src: url("/fonts/Poppins-SemiBold.ttf") format("truetype");
        font-style: normal;
    }

    html,
    a {
        font-family: 'Poppins Regular', sans-serif;
    }
    
    
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            /* box-shadow: #2e186a 0px 0px 0px 1px; */
        }
    }

    h1 {
        font-family: 'Poppins Medium', serif;
        color: ${(props) => props.theme.colors.black};
        font-size: 56px;
        line-height: 1.5rem;
        margin:0;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
          line-height: 2.5rem;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 24px;
        }
    }

    h2 {
        font-family: 'Poppins Medium', serif;
        color: ${(props) => props.theme.colors.black};
        font-size: 2rem;
        line-height: 1.5rem;
        margin:0;

        @media only screen and (max-width: 890px) {
          font-size: 1.5rem;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 18px;
        }
    }

    h3 {
        font-family: 'Poppins Medium', serif;
        color: ${(props) => props.theme.colors.black};
        font-size: 18px;
        line-height: 1.5rem;
        margin:0;

        @media only screen and (max-width: 890px) {
          font-size: 22px;
          /* line-height: 2.5rem; */
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 16px;
        }
    }

    h4 {
        font-family: 'Poppins Medium', serif;
        color: ${(props) => props.theme.colors.black};
        font-size: 16px;
        line-height: 1.5rem;
        margin:0;

        @media only screen and (max-width: 890px) {
            font-size: 14px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 14px;
        }
    } 

    h5 {
        font-family: 'Poppins Medium', serif;
        color: ${(props) => props.theme.colors.black};
        font-size: 14px;
        line-height: 1.5rem;
        margin:0;

        @media only screen and (max-width: 890px) {
            font-size: 12px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 12px;
        }
    }

    h6 {
        font-family: 'Poppins Medium', serif;
        color: ${(props) => props.theme.colors.black};
        font-size: 56px;
        line-height: 1.5rem;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }
`;
