import { Layout } from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Redirect, Route } from "react-router-dom";
import { JWTType } from "../../common/types";
import Header from "../Header";
import SideBar from "../SideBar";

const ProtectedRouteWithSidebar = ({ component: Component, ...rest }: any) => {
  const { Content } = Layout;
  const { path } = rest;

  if (Cookies.get("tokenAuth")) {
    console.log("Buscando redirect auth");
    const tokenAuth = Cookies.get("tokenAuth");
    if (tokenAuth) {
      const jwtToken = JSON.stringify(tokenAuth);

      let jwtDecoded: JWTType = jwt_decode(jwtToken || "") || null;

      localStorage.setItem("tokenAuth", jwtToken);
      localStorage.setItem("userEmail", jwtDecoded.user_name);

      Cookies.remove("tokenAuth", { path: "", domain: ".cashgo.com.br" });
    }
  }

  if (localStorage.getItem("tokenAuth") === null) {
    return <Redirect to={"/login"} />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideBar />
      <Layout className="site-layout">
        <Header />
        <Content style={{ margin: "0 16px", padding: "24px" }}>
          <Route
            // {...rest}
            path={path[0]}
            render={(rest) => {
              return (
                <main className="content">
                  <Component {...rest} />
                </main>
              );
            }}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export { ProtectedRouteWithSidebar };
