import {
  BellOutlined, LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Badge, Dropdown, Layout, Menu, notification, Space } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FirebaseConfig from "../../firebase";
import { buscarCountNotificacoesNaoLidas } from "../../services/notificacao/notificacaoService";
import Notificacoes from "../Notificacoes/notificacoes";

const Header = ({ t }: any) => {
  const [notificacoesNaoLidas, setNotificacoesNaoLidas] = useState<number>(0);
  const [visibleNotificacoes, setVisibleNotificacoes] = useState(false);
  const { Header } = Layout;

  const showNotificacoes = () => {
    setVisibleNotificacoes(!visibleNotificacoes);
  };

  const onCloseNotificacoes = () => {
    setVisibleNotificacoes(!visibleNotificacoes);
  };

  const getNotificacoesNaoLidas = () => {
    buscarCountNotificacoesNaoLidas()
      .then((response) => {
        setNotificacoesNaoLidas(response.data);
      })
      .catch((error) => {
        console.error(
          notification.error({
            message: "Não foi possível consultar as notificações.",
          }),
          error
        );
      })
  }

  useEffect(() => {
    getNotificacoesNaoLidas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = (
    <Menu className="usuario-dropdown">
      <Menu.Item
        style={{
          textAlign: "center",
          paddingBottom: "2em",
          paddingTop: "1em",
        }}
        key="0"
      >
        <Link to={"/usuario/alterar-senha"}>
          <UserOutlined />
          <span style={{ marginLeft: "8px" }}>Usuário</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={{
          borderTop: "1px solid #FF8727",
          borderRadius: "5px",
          textAlign: "center",
        }}
        key="1"
      >
        <Link to={"/logout"}>
          <LogoutOutlined />
          <span style={{ marginLeft: "8px" }}>Sair</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <FirebaseConfig
        requestPermission={false}
        atualizarCountNotificacoes={getNotificacoesNaoLidas}
      />

      {visibleNotificacoes &&
        <Notificacoes
          showNotificacoes={visibleNotificacoes}
          onCloseNotificacoes={onCloseNotificacoes}
          notificacoesNaoLidas={notificacoesNaoLidas}
          setNotificacoesNaoLidas={setNotificacoesNaoLidas}
        />
      }

      <Header
        style={{
          paddingLeft: "1em",
          backgroundColor: "#F2F2F2",
          color: "#259A47",
          fontSize: "16px",
          fontFamily: "Poppins Medium",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
        }}
      >
        {" "}
        Portal administrativo

        <Space size={'large'}>
          <Badge size={"small"} count={notificacoesNaoLidas} color="red" offset={[-1, 2]}>
            <BellOutlined onClick={showNotificacoes}
              style={{
                color: "black",
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Badge>

          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <SettingOutlined
              style={{
                color: "black",
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </Dropdown>
        </Space>
      </Header>
    </>
  );
};

export default Header;
