import { Environments } from "../../environments/environments";

function getEnvValue(key) {
  if (process.env.REACT_APP_ENVIRONMENT === "dev") {
    return Environments[`${key}_DEV`];
  }
  if (process.env.REACT_APP_ENVIRONMENT === "prd") {
    return Environments[`${key}`];
  }
  return Environments[`${key}_LOCAL`];
}

function isProduction() {
  return process.env.REACT_APP_ENVIRONMENT === "prd";
}

function isDev() {
  return process.env.REACT_APP_ENVIRONMENT === "dev";
}

export { getEnvValue, isProduction, isDev };
