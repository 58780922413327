export enum Environments {
    REACT_APP_PORTAL_API_HOST = "https://api.cashgo.com.br/portal-api",
    REACT_APP_AUTH_HOST = "https://auth.cashgo.com.br",
    REACT_APP_PORTAL_HOST = "https://portal.cashgo.com.br/#/antecipacao",
    REACT_APP_PORTAL_URL = "https://portal.cashgo.com.br",
    REACT_APP_LANDING_PAGE_API_HOST = "https://api.cashgo.com.br/landing-page-api",
    REACT_APP_FINANCEIRO_API_HOST = "https://api.cashgo.com.br/financeiro-api",
    REACT_APP_CAMPANHA_API_HOST = "https://api.cashgo.com.br/campanha-api",
    REACT_APP_RETAGUARDA_API_HOST = "https://api.cashgo.com.br/retaguarda-api",
    REACT_APP_PARCEIRO_API_HOST = "https://api.cashgo.com.br/portal-parceiro-api",
    REACT_FIREBASE_TOKEN_PROD = "BPC63YvdZAMUqP6588h6I8zZxiOZTo-eNsQv_9tCYOSoDm_HaOaF8G84D1oMqA-QAijmFlUw-eSLKugMIOu8r18",

    REACT_APP_PORTAL_API_HOST_DEV = "https://dev-api.cashgo.com.br/portal-api",
    REACT_APP_AUTH_HOST_DEV = "https://dev-auth.cashgo.com.br",
    REACT_APP_PORTAL_HOST_DEV = "http://dev.portal.cashgo.com.br/#/antecipacao",
    REACT_APP_PORTAL_URL_DEV = "http://dev.portal.cashgo.com.br",
    REACT_APP_LANDING_PAGE_API_HOST_DEV = "https://dev-api.cashgo.com.br/landing-page-api",
    REACT_APP_FINANCEIRO_API_HOST_DEV = "https://dev-api.cashgo.com.br/financeiro-api",
    REACT_APP_CAMPANHA_API_HOST_DEV = "https://dev-api.cashgo.com.br/campanha-api",
    REACT_APP_RETAGUARDA_API_HOST_DEV = "https://dev-api.cashgo.com.br/retaguarda-api",
    REACT_APP_PARCEIRO_API_HOST_DEV = "https://dev-api.cashgo.com.br/portal-parceiro-api",
    REACT_FIREBASE_TOKEN_DEV = "BF9OwP7WH87ITaKmdlLxLTN2jYamDAblLFIyUMMqAokbm0mOVVayZrahfNIu9nI-4sVi6kNQjQxddSezqlnA1NY",

    REACT_APP_PORTAL_API_HOST_LOCAL = 'http://localhost:8082/portal-api',
    REACT_APP_AUTH_HOST_LOCAL = "https://dev-auth.cashgo.com.br",
    REACT_APP_PORTAL_HOST_LOCAL = "http://localhost:3001/#/antecipacao",
    REACT_APP_PORTAL_URL_LOCAL = "http://localhost:3001",
    REACT_APP_LANDING_PAGE_API_HOST_LOCAL = "http://localhost:8080/landing-page-api",
    REACT_APP_FINANCEIRO_API_HOST_LOCAL = "http://localhost:8086/financeiro-api",
    REACT_APP_CAMPANHA_API_HOST_LOCAL = "http://localhost:8085/campanha-api",
    REACT_APP_RETAGUARDA_API_HOST_LOCAL = "http://localhost:3001/retaguarda-api",
    REACT_APP_PARCEIRO_API_HOST_LOCAL = "http://localhost:3000/portal-parceiro-api",
    REACT_FIREBASE_TOKEN_LOCAL = "BF9OwP7WH87ITaKmdlLxLTN2jYamDAblLFIyUMMqAokbm0mOVVayZrahfNIu9nI-4sVi6kNQjQxddSezqlnA1NY",

    REACT_APP_DEFAULT_MAX_PARCELAS = "24",
    REACT_APP_DEFAULT_TAXA_1_A_3 = "6",
    REACT_APP_DEFAULT_TAXA_4_A_6 = "4.5",
    REACT_APP_DEFAULT_TAXA_7_A_24 = "3.8",
    REACT_APP_DEFAULT_MAX_RETENCAO_ALUGUEL = "95",
}