import { Badge, Button, Card, Row, Col, Tooltip } from "antd";
import { NotificacaoResponse } from "../../common/types";
import { formatarDataHora } from "../../common/utils/dateTimeUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFolderOpen, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

type NotificacoesProps = {
  notificacao: NotificacaoResponse;
  alterarVisualizacao: (idMensagem: number, visualizada: boolean, quantidadeMensagens: number) => void;
};

const Notificacoes = ({
  notificacao,
  alterarVisualizacao,
}: NotificacoesProps) => {
  const handleOpenLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Card
        bodyStyle={{ padding: "5px" }}
        style={{ width: "100%", marginBottom: "10px", padding: "5px" }} bordered={true}
        key={notificacao.id}
      >
        <Row style={{ marginBottom: "5px" }} justify="space-between">
          <h3
            style={{
              fontSize: "14px",
              color: "#8E8E8E",
              fontStyle: "normal",
              fontWeight: "500",
              fontFamily: "Poppins Regular",
              lineHeight: "20px"
            }}
          >{notificacao.mensagemTitulo}</h3>

          {!notificacao.visualizada &&
            <Badge size="default" status="success" offset={[0, -10]} style={{ fontSize: 16 }} />
          }
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <span
            style={{
              fontSize: "16px",
              color: "#141414",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Poppins Regular",
              lineHeight: "22px"
            }}>{notificacao.mensagemBody}</span>
        </Row>
        <Row justify="space-between">
          <Col>
            <span
              style={{
                fontSize: "12px",
                color: "#8E8E8E",
                fontStyle: "normal",
                fontWeight: "400",
                fontFamily: "Poppins Regular",
                lineHeight: "16px"
              }}>{formatarDataHora(notificacao.dataCriacao)}</span>
          </Col>

          <Col>
            {notificacao.mensagemLink &&
              <Button type="link" onClick={() => handleOpenLink(notificacao.mensagemLink)} size={"small"}>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ color: "#107FE5" }} />
              </Button>
            }

            <Button type="link" onClick={() => alterarVisualizacao(notificacao.id, !notificacao.visualizada, 1)} size={"small"}>
              <Tooltip title={notificacao.visualizada ? "Marcar como não lida" : "Marcar como lida"}>
                <FontAwesomeIcon icon={notificacao.visualizada ? faFolderOpen : faFolder} style={{ color: notificacao.visualizada ? "#818181" : "#141414" }} />
              </Tooltip>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Notificacoes;
