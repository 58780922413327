import { LoadingOutlined } from "@ant-design/icons";
import { Button, Drawer, Empty, notification, Pagination, Row, Spin, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { useEffect, useState } from "react";
import { AtualizacaoVisualizacaNotificacaoRequest, ConsultaNotificacoesRequest, NotificacaoPaginadoResponse, NotificacaoResponse } from "../../common/types";
import { atualizarVisualizacao, atualizarVisualizacaoLote, buscarNotificacoes } from "../../services/notificacao/notificacaoService";
import Notificacao from "../Notificacoes/notificacao";

type NotificacoesProps = {
  showNotificacoes: boolean;
  notificacoesNaoLidas: number;
  onCloseNotificacoes: () => void;
  setNotificacoesNaoLidas: (e: number) => void;
};

const Notificacoes = ({
  showNotificacoes,
  notificacoesNaoLidas,
  onCloseNotificacoes,
  setNotificacoesNaoLidas,
}: NotificacoesProps) => {
  const defaultTab: string = "TODAS";

  const [notificacoesPaginado, setNotificacoesPaginado] = useState<NotificacaoPaginadoResponse>();
  const [notificacoes, setNotificacoes] = useState<NotificacaoResponse[]>([]);
  const [filtro, setFiltro] = useState<ConsultaNotificacoesRequest>({ page: 1, size: 20 });
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(defaultTab);

  const alterarVisualizacaoTodasMensagens = (visualizada: boolean) => {
    const ids: number[] = notificacoes.filter((item) => !item.visualizada).map(item => item.id)
    const request: AtualizacaoVisualizacaNotificacaoRequest = {
      visualizada: visualizada,
      ids: ids
    }
    
    setLoading(true);
    atualizarVisualizacaoLote(request)
      .then((response) => {
        setNotificacoesNaoLidas(notificacoesNaoLidas - ids.length)
        ids.forEach((item) => alterarVisualizacaoMensagem(item, visualizada));
      })
      .catch((error) => {
        console.error(
          notification.error({
            message: "Não foi possível atualizar a visualização das mensagens.",
          }),
          error
        )
      }).finally(() => setLoading(false));
  }

  const existeMensgensNaoVisualizadasPagina = () => {
    return notificacoes.some(item => !item.visualizada);
  }

  const alterarVisualizacaoMensagem = (idMensagem: number, visualizada: boolean) => {
    const newData = [...notificacoes];
    let itemIndex = newData.findIndex((item) => Number(idMensagem) === item.id);
    const item = newData[itemIndex];
    item.visualizada = visualizada;
    newData.splice(itemIndex, 1, { ...item });
    setNotificacoes(newData);
  }

  const alterarVisualizacao = (idMensagem: number, visualizada: boolean, quantidadeMensagens: number) => {
    const request: AtualizacaoVisualizacaNotificacaoRequest = {
      visualizada: visualizada
    }

    setLoading(true);
    atualizarVisualizacao(idMensagem, request)
      .then((response) => {
        const quantidadeNaoLidas = visualizada ? -quantidadeMensagens : quantidadeMensagens;
        alterarVisualizacaoMensagem(idMensagem, visualizada);
        setNotificacoesNaoLidas(notificacoesNaoLidas + quantidadeNaoLidas)
      })
      .catch((error) => {
        console.error(
          notification.error({
            message: "Não foi possível atualizar a visualização da mensagem.",
          }),
          error
        )
      }).finally(() => setLoading(false));
  }

  function changePagination(page: number) {
    setFiltro({ ...filtro, page });
  }

  const handleTabChange = (key: any) => {
    setActiveTab(key);
    let visualizada = null;

    if (key === 'NAO_LIDAS') {
      visualizada = false;
    } else if (key === 'LIDAS') {
      visualizada = true;
    }

    setFiltro({
      ...filtro,
      visualizada: visualizada,
      page: 1
    })
  };

  const getNotificacoesFiltro = () => {
    const request: ConsultaNotificacoesRequest = {
      page: filtro.page - 1,
      size: filtro.size,
      visualizada: filtro.visualizada
    }

    setLoading(true);
    buscarNotificacoes(request)
      .then((response) => {
        setNotificacoes(response.data.content);
        setNotificacoesPaginado(response.data)
      })
      .catch((error) => {
        console.error(
          notification.error({
            message: "Não foi possível consultar as notificações.",
          }),
          error
        );
      }).finally(() => setLoading(false));
  }

  const filtroDefault = () => {
    setFiltro({
      ...filtro,
      visualizada: null,
      page: 1
    })
  }

  const handleClose = () => {
    filtroDefault();
    setActiveTab(defaultTab);
    onCloseNotificacoes();
  }

  useEffect(() => {
    getNotificacoesFiltro();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro, showNotificacoes === true]);

  return (
    <>
      <Drawer
        width={"450px"}
        title="Notificações"
        visible={showNotificacoes}
        onClose={handleClose}
        destroyOnClose={true}
        bodyStyle={{ padding: "0px 15px 15px 15px" }}
        headerStyle={{ padding: "24px 24px 24px 15px" }}
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 50, color: "#2D9A47" }} spin />}
        >
          <Tabs defaultActiveKey={activeTab} onChange={handleTabChange} style={{ marginBottom: "10px" }}>
            <TabPane
              tab={<span style={{ fontSize: "16px" }}>Todas</span>}
              key="TODAS"
            >
              {notificacoes.length > 0 && existeMensgensNaoVisualizadasPagina() &&
                <Row>
                  <Button onClick={() => alterarVisualizacaoTodasMensagens(true)} type="primary">
                    Marcar todas como lidas
                  </Button>
                </Row>
              }
            </TabPane>
            <TabPane
              tab={<span style={{ fontSize: "16px" }}>Não lidas</span>}
              key="NAO_LIDAS"
            >
              {notificacoes.length > 0 && existeMensgensNaoVisualizadasPagina() &&
                <Row>
                  <Button onClick={() => alterarVisualizacaoTodasMensagens(true)} type="primary">
                    Marcar todas como lidas
                  </Button>
                </Row>
              }
            </TabPane>
            <TabPane
              tab={<span style={{ fontSize: "16px" }}>Lidas</span>}
              key="LIDAS"
            >
            </TabPane>
          </Tabs>

          {notificacoes.length === 0 &&
            <Empty />
          }

          {notificacoes.map(notificacao =>
            <Notificacao
              notificacao={notificacao}
              alterarVisualizacao={alterarVisualizacao}
            />
          )
          }

          {notificacoes.length !== 0 &&
            < Row justify="end">
              <Pagination
                onChange={changePagination}
                size="small"
                total={notificacoesPaginado?.totalElements || 1}
                defaultCurrent={1}
                pageSize={notificacoesPaginado?.size || 1}
              />
            </Row>
          }
        </Spin>
      </Drawer>
    </>
  );
};

export default Notificacoes;