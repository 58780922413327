import { AxiosRequestConfig } from "axios";
import QueryString from "qs";
import {
  AtualizacaoVisualizacaNotificacaoRequest, ConsultaNotificacoesRequest,
  CriacaoDispositivoNotificacaoRequest, NotificacaoPaginadoResponse
} from "../../common/types";
import { retaguardaApi } from "../api";

const notificacaoResource = "/notificacoes";

const headers: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

async function buscarNotificacoes(filtros: ConsultaNotificacoesRequest) {
  const configs = {
    ...headers,
    params: filtros,
    paramsSerializer: (params: any) => {
      return QueryString.stringify(params);
    },
  };

  return retaguardaApi.get<NotificacaoPaginadoResponse>(
    `${notificacaoResource}/mensagens`,
    configs);
}

async function buscarCountNotificacoesNaoLidas() {
  const configs = {
    ...headers
  };

  return retaguardaApi.get<number>(
    `${notificacaoResource}/mensagens/count-nao-lidas`,
    configs);
}

async function atualizarVisualizacao(idMensagem: number, dadosRequest: AtualizacaoVisualizacaNotificacaoRequest) {
  return await retaguardaApi.patch<AtualizacaoVisualizacaNotificacaoRequest>(
    `${notificacaoResource}/mensagens/${idMensagem}/visualizacao`,
    dadosRequest,
    headers
  );
}

async function atualizarVisualizacaoLote(dadosRequest: AtualizacaoVisualizacaNotificacaoRequest) {
  return await retaguardaApi.post<AtualizacaoVisualizacaNotificacaoRequest>(
    `${notificacaoResource}/mensagens/visualizacao/lote`,
    dadosRequest,
    headers
  );
}

async function salvarDispositivo(dadosRequest: CriacaoDispositivoNotificacaoRequest) {
  return await retaguardaApi.post<CriacaoDispositivoNotificacaoRequest>(
    `${notificacaoResource}/dipositivos`,
    dadosRequest,
    headers
  );
}

export {
  buscarNotificacoes,
  buscarCountNotificacoesNaoLidas,
  atualizarVisualizacao,
  atualizarVisualizacaoLote,
  salvarDispositivo
};

