export const defaultTheme = {
    name: 'default',
    colors: {
        primary: '#2D9A47',
        secondary: '#4CABFF',
        gray: '#92949C',
        lightGray: '#DEDEDE',
        black: '#130F1C',
        white: '#fff',
        background: '#2c3e50',
        border: '#2980b9',
    }
}

export const firstTheme = {
    name: 'first',
    colors: {
        primary: '#16a085',
        secondary: '#4CABFF',
        black: '#ecf0f1',
        background: '#2c3e50',
        border: '#2980b9',
    }
}

export const secondTheme = {
    name: 'second',
    colors: {
        primary: '#8e44ad',
        secondary: '#4CABFF',
        black: '#34495e',
        background: '#e74c3c',
        border: '#27ae60',
    }
}