import moment, { Moment } from "moment";
import { eUsuarioAdminRenegociacao } from "./securityUtils";

const desabilitarDataPassada = (current: any) => {
    let customDate = moment().format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
}

const desabilitarDataFutura = (current: any) => {
    let customDate = moment().format("YYYY-MM-DD");
    return current && current > moment(customDate, "YYYY-MM-DD");
}

const desabilitarDataEntradaFuturaUsuariosComuns = (current: any) => {
    if (!eUsuarioAdminRenegociacao()) {
        return desabilitarDataFutura(moment(current).toDate())
    }
}

const disableDateRanges = (current: Moment, startDate: Moment, endDate: Moment) => {
    let startCheck = true;
    let endCheck = true;
    startCheck = current && current < moment(startDate, 'YYYY-MM-DD');
    endCheck = current && current > moment(endDate, 'YYYY-MM-DD');

    return startCheck || endCheck;
}

const calcularIdade = (dataNascimento: any) => {
    let diff = moment().diff(moment(dataNascimento, "YYYY-MM-DD"));
    return moment.duration(diff).asYears().toFixed(0);
}

const formatarData = (data: any) => {
    let date = moment(data);

    if (date.isValid()) {
        return moment(date).format("DD/MM/YYYY");
    }
    return "Não informado";
}

const formatarDataHora = (data: any) => {
    let date = moment(data);

    if (date.isValid()) {
        return moment(date).format("DD/MM/YYYY HH:mm");
    }
    return "Não informado";
}

const removerFormatacaoData = (data: Moment) => {
    return data ? moment(data, "DD/MM/YYYY").format("YYYY-MM-DD") : "";
};

const calcularDiferencaDias = (dataInicial: Moment, dataFinal: Moment): number => {
    const dataInicio = dataInicial.toDate();
    const dataFim = dataFinal.toDate();

    dataInicio.setHours(0, 0, 0, 0);
    dataFim.setHours(0, 0, 0, 0);

    const diferencaMilissegundos = dataFim.getTime() - dataInicio.getTime();

    const diferencaDias = Math.ceil(diferencaMilissegundos / (1000 * 60 * 60 * 24));

    return diferencaDias;
};

const getUltimoDiaUtil = (): moment.Moment => {
    let lastDay = moment().subtract(1, 'days');
  
    if (lastDay.day() === 6) {
      lastDay.subtract(1, 'days');
    } else if (lastDay.day() === 0) {
      lastDay.subtract(2, 'days');
    }
    return lastDay;
  }

export { desabilitarDataPassada, calcularIdade, formatarData, formatarDataHora, disableDateRanges, removerFormatacaoData, desabilitarDataFutura, desabilitarDataEntradaFuturaUsuariosComuns, calcularDiferencaDias, getUltimoDiaUtil };
