import axios from "axios";
import { getEnvValue } from "../common/utils/environmentUtils";

const authApi = axios.create({
  baseURL: getEnvValue("REACT_APP_AUTH_HOST"),
});

const portalApi = axios.create({
  baseURL: getEnvValue("REACT_APP_PORTAL_API_HOST"),
});

const portalApiWithOutToken = axios.create({
  baseURL: getEnvValue("REACT_APP_PORTAL_API_HOST"),
});

const financeiroApi = axios.create({
  baseURL: getEnvValue("REACT_APP_FINANCEIRO_API_HOST"),
});

const campanhaApi = axios.create({
  baseURL: getEnvValue("REACT_APP_CAMPANHA_API_HOST"),
});

const retaguardaApi = axios.create({
  baseURL: getEnvValue("REACT_APP_RETAGUARDA_API_HOST"),
});

const retaguardaApiWithOutToken = axios.create({
  baseURL: getEnvValue("REACT_APP_RETAGUARDA_API_HOST"),
});

const portalParceiroApi = axios.create({
  baseURL: getEnvValue("REACT_APP_PARCEIRO_API_HOST"),
});

function montaFiltrosRequest(filtros: any) {
  if (filtros) {
    var filtrosRequest: any[] = [];
    Object.entries(filtros).forEach(([key, value], index) => {
      if (value !== "" && value !== undefined) {
        filtrosRequest = filtrosRequest.concat(`${key}=${value}`);
      }
    });
    return filtrosRequest.join("&");
  }
  return "";
}

function handlerError(error: any) {

  if (error.response.status === 401) {
    alert("Sua sessão expirou, necessário fazer o login novamente!");
    error.response = {
      data: {
        message: "Sua sessão expirou, necessário fazer o login novamente!",
      },
    };
    localStorage.removeItem("tokenAuth");
    window.location.href = "/";
    return error;
  } else if (error.response.status === 403) {
    error.response = {
      data: {
        message: "Sem autorização para acessar a funcionalidade!",
      },
    };
    return error;
  } else if (!error.response.data.message) {

    if (error.response.data.details) {

      error.response = {
        data: {
          message: error.response.data.details,
        },
      };
    } else {
      error.response = {
        data: {
          message: "Ocorreu um erro ao executar a operação.",
        },
      };
    }
    return error;
  }
  return error;
}

portalApi.interceptors.request.use(
  function (config) {
    const token = JSON.parse(localStorage.getItem("tokenAuth") || "");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

portalApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(handlerError(error));
  }
);

financeiroApi.interceptors.request.use(
  function (config) {
    const token = JSON.parse(localStorage.getItem("tokenAuth") || "");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

financeiroApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(handlerError(error));
  }
);

retaguardaApi.interceptors.request.use(
  function (config) {
    const token = JSON.parse(localStorage.getItem("tokenAuth") || "");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

retaguardaApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(handlerError(error));
  }
);

campanhaApi.interceptors.request.use(
  function (config) {
    const token = JSON.parse(localStorage.getItem("tokenAuth") || "");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

campanhaApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(handlerError(error));
  }
);

portalParceiroApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(handlerError(error));
  }
);

export {
  authApi,
  portalApi,
  portalApiWithOutToken,
  financeiroApi,
  campanhaApi,
  retaguardaApi,
  portalParceiroApi,
  retaguardaApiWithOutToken,
  montaFiltrosRequest,
};
