import { AuditOutlined, BankOutlined, DollarCircleOutlined, MailOutlined, ShopOutlined, ContactsOutlined } from "@ant-design/icons";
import React from "react";

export interface MenuItemRetaguarda {
  key: React.Key;
  label: string;
  roles: RoleRetaguarda[];
  icon?: React.ReactNode;
  style?: React.ReactNode;
  path?: string;
  parent?: string;
}

export enum RoleRetaguarda {
  ROLE_CASHGO = "ROLE_USER_CASHGO",
  ROLE_CREDITO = "ROLE_USER_CREDITO_CASHGO",
  ROLE_COMERCIAL = "ROLE_USER_COMERCIAL_CASHGO",
  ROLE_COMERCIAL_B2C = "ROLE_USER_COMERCIAL_B2C",
  ROLE_COMERCIAL_B2B = "ROLE_USER_COMERCIAL_B2B",
  ROLE_COORDENADOR_B2B = "ROLE_USER_COORDENADOR_B2B",
  ROLE_RESPONSAVEL_ADESAO_CONTRATO_ANEXADO_ATUALIZACAO = "ROLE_RESPONSAVEL_ADESAO_CONTRATO_ANEXADO_ATUALIZACAO",
  ROLE_FINANCEIRO = "ROLE_USER_FINANCEIRO",
  ROLE_FINANCEIRO_CONTAS_RECEBER = "ROLE_USER_FINANCEIRO_CONTAS_RECEBER",
  ROLE_FINANCEIRO_CONTAS_PAGAR = "ROLE_USER_FINANCEIRO_CONTAS_PAGAR",
  ROLE_FINANCEIRO_COBRANCA = "ROLE_USER_FINANCEIRO_COBRANCA",
  ROLE_RESPONSAVEL_COBRANCA_IMOBILIARIA_ATUALIZACAO = "ROLE_RESPONSAVEL_COBRANCA_IMOBILIARIA_ATUALIZACAO",
  ROLE_FINANCEIRO_GESTAO_COMISSAO = "ROLE_USER_FINANCEIRO_GESTAO_COMISSAO",
  ROLE_USER_ADMIN_LIMITE_CREDITO = "ROLE_USER_ADMIN_LIMITE_CREDITO",
  ROLE_PROPRIETARIOS_LISTAGEM = "ROLE_PROPRIETARIOS_LISTAGEM",
  ROLE_PROPOSTA_ETAPA_PAGAMENTO_ATUALIZACAO = "ROLE_PROPOSTA_ETAPA_PAGAMENTO_ATUALIZACAO",
  ROLE_USER_FINANCEIRO_ADMIN_RENEGOCIACAO = "ROLE_USER_FINANCEIRO_ADMIN_RENEGOCIACAO",
  ROLE_FINANCEIRO_CONTRATOS_PARCELAS_GARANTIA_LISTAGEM = "ROLE_FINANCEIRO_CONTRATOS_PARCELAS_GARANTIA_LISTAGEM",
  ROLE_PARCEIRO_ARQUIVO_IMOBILIARIA_ATUALIZACAO = "ROLE_PARCEIRO_ARQUIVO_IMOBILIARIA_ATUALIZACAO",
  ROLE_PARCEIRO_ARQUIVO_LOCADORES_ATUALIZACAO = "ROLE_PARCEIRO_ARQUIVO_LOCADORES_ATUALIZACAO",
  ROLE_PARCEIRO_DOCUMENTO_CONTRATO_ATUALIZACAO = "ROLE_PARCEIRO_DOCUMENTO_CONTRATO_ATUALIZACAO",
  ROLE_ENDERECO_PROPRIETARIO_ALTERACAO = "ROLE_ENDERECO_PROPRIETARIO_ALTERACAO",
  ROLE_DADOS_DOCUMENTO_OCR_EDICAO = "ROLE_DADOS_DOCUMENTO_OCR_EDICAO",
}
const menusRetaguarda: MenuItemRetaguarda[] = [
  {
    key: "10",
    label: "Cadastros",
    icon: <ContactsOutlined className="icones-default"/>,
    roles: [RoleRetaguarda.ROLE_PROPRIETARIOS_LISTAGEM],
  },
  {
    key: "11",
    label: "Proprietários",
    path: "/cadastros/proprietarios",
    parent: "10",
    roles: [RoleRetaguarda.ROLE_PROPRIETARIOS_LISTAGEM],
  },
  {
    key: "100",
    label: "Crédito",
    icon: <BankOutlined className="icones-default"/>,
    roles: [RoleRetaguarda.ROLE_CREDITO],
  },
  {
    key: "102",
    label: "Adesão B2B",
    path: "/credito/adesoes",
    parent: "100",
    roles: [RoleRetaguarda.ROLE_CREDITO],
  },
  {
    key: "101",
    label: "Proposta",
    path: "/credito/propostas",
    parent: "100",
    roles: [RoleRetaguarda.ROLE_CREDITO],
  },
  {
    key: "200",
    label: "Comercial B2C",
    icon: <ShopOutlined className="icones-default"/>,
    roles: [RoleRetaguarda.ROLE_COMERCIAL_B2C],
  },
  {
    key: "201",
    label: "Simulações",
    path: "/comercial/simulacoes",
    parent: "200",
    roles: [RoleRetaguarda.ROLE_COMERCIAL_B2C],
  },
  {
    key: "202",
    label: "Propostas",
    path: "/comercial/propostas",
    parent: "200",
    roles: [RoleRetaguarda.ROLE_COMERCIAL_B2C],
  },
  {
    key: "204",
    label: "BlockList de antecipação",
    path: "/blocklist-antecipacao",
    parent: "200",
    roles: [RoleRetaguarda.ROLE_COMERCIAL_B2C],
  },
  {
    key: "300",
    label: "Financeiro",
    icon: <DollarCircleOutlined className="icones-default"/>,
    roles: [
      RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_PAGAR,
      RoleRetaguarda.ROLE_FINANCEIRO,
      RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER,
      RoleRetaguarda.ROLE_FINANCEIRO_COBRANCA,
      RoleRetaguarda.ROLE_FINANCEIRO_GESTAO_COMISSAO,
    ],
  },
  {
    key: "301",
    label: "Consulta de Parcelas",
    path: "/financeiro/consulta-parcelas",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO],
  },
  {
    key: "302",
    label: "Baixa de operações",
    path: "/financeiro/contas-pagar/baixa-parcelas",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_PAGAR],
  },
  {
    key: "303",
    label: "Baixa de Parcelas a receber",
    path: "/financeiro/contas-receber/baixa-parcelas",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER],
  },
  {
    key: "309",
    label: "Gestão de recebimento de caução",
    path: "/financeiro/garantia-contrato/baixa-parcelas-caucao",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_CONTRATOS_PARCELAS_GARANTIA_LISTAGEM],
  },
  {
    key: "310",
    label: "Gestão de devolução de caução",
    path: "/financeiro/garantia-contrato/baixa-devolucao-caucao",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_CONTRATOS_PARCELAS_GARANTIA_LISTAGEM],
  },
  {
    key: "304",
    label: "Cobrança",
    path: "/financeiro/cobranca",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_COBRANCA],
  },
  {
    key: "305",
    label: "Gestão de cards de cobrança",
    path: "/financeiro/cobranca/gestao-cards",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_RESPONSAVEL_COBRANCA_IMOBILIARIA_ATUALIZACAO],
  },
  {
    key: "306",
    label: "Desfazer Baixas",
    path: "/financeiro/baixas/desfazer",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER],
  },
  {
    key: "307",
    label: "Gestão de comissões",
    path: "/financeiro/gestao-comissoes",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_GESTAO_COMISSAO],
  },
  {
    key: "310",
    label: "Gestão de remessas de boleto",
    path: "/financeiro/gestao-remessa-boletos",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER],
  },
  {
    key: "311",
    label: "Relatório de conciliação",
    path: "/financeiro/relatorio-conciliacao",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER],
  },
  {
    key: "312",
    label: "Conciliação de transações",
    path: "/financeiro/conciliacao-pessoas",
    parent: "300",
    roles: [RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER],
  },
  {
    key: "600",
    label: "Contratos",
    icon: <AuditOutlined className="icones-default"/>,
    roles: [
      RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_PAGAR,
      RoleRetaguarda.ROLE_FINANCEIRO,
      RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER,
      RoleRetaguarda.ROLE_FINANCEIRO_COBRANCA,
    ],
  },
  {
    key: "601",
    label: "Consulta de contratos",
    parent: "600",
    path: "/contrato/contratos",
    roles: [
      RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_PAGAR,
      RoleRetaguarda.ROLE_FINANCEIRO,
      RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER,
      RoleRetaguarda.ROLE_FINANCEIRO_COBRANCA,
    ],
  },
  {
    key: "602",
    label: "Pendentes de relocação",
    parent: "600",
    path: "/contrato/pendentes-relocacao",
    roles: [
      RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_PAGAR,
      RoleRetaguarda.ROLE_FINANCEIRO,
      RoleRetaguarda.ROLE_FINANCEIRO_CONTAS_RECEBER,
      RoleRetaguarda.ROLE_FINANCEIRO_COBRANCA,
    ],
  },
  {
    key: "400",
    label: "Comercial B2B",
    icon: <ShopOutlined className="icones-default"/>,
    roles: [RoleRetaguarda.ROLE_CASHGO],
  },
  {
    key: "404",
    label: "Adesão B2B",
    path: "/comercial/adesoes",
    parent: "400",
    roles: [RoleRetaguarda.ROLE_COMERCIAL_B2B],
  },
  {
    key: "402",
    label: "BlockList de comunicação",
    path: "/blocklist",
    parent: "400",
    roles: [RoleRetaguarda.ROLE_CASHGO],
  },
  {
    key: "405",
    label: "Blocklist e-mail",
    path: "/unsubscribe-email",
    parent: "400",
    roles: [RoleRetaguarda.ROLE_COMERCIAL],
  },
  {
    key: "401",
    label: "Imobiliárias",
    path: "/imobiliarias",
    parent: "400",
    roles: [RoleRetaguarda.ROLE_CASHGO],
  },
  {
    key: "403",
    label: "Parceiros",
    path: "/parceiros",
    parent: "400",
    roles: [RoleRetaguarda.ROLE_CASHGO],
  },
  {
    key: "500",
    label: "Campanha",
    icon: <MailOutlined className="icones-default"/>,
    roles: [RoleRetaguarda.ROLE_COMERCIAL_B2B],
  },
  {
    key: "501",
    label: "Campanhas",
    path: "/campanhas",
    parent: "500",
    roles: [RoleRetaguarda.ROLE_CASHGO],
  },
  {
    key: "502",
    label: "Autorizar Campanha",
    path: "/campanhas-autorizacao",
    parent: "500",
    roles: [RoleRetaguarda.ROLE_COORDENADOR_B2B],
  },
  {
    key: "800",
    label: "Ambiente ERP",
    icon: <ShopOutlined className="icones-default"/>,
    roles: [RoleRetaguarda.ROLE_PARCEIRO_ARQUIVO_IMOBILIARIA_ATUALIZACAO],
  },
  {
    key: "801",
    label: "Adesão B2B",
    path: "/erp-adesao-imobiliarias",
    parent: "800",
    roles: [RoleRetaguarda.ROLE_PARCEIRO_ARQUIVO_IMOBILIARIA_ATUALIZACAO],
  },
  {
    key: "802",
    label: "Comunicação e pessoas associadas",
    path: "/erp-locadores",
    parent: "800",
    roles: [RoleRetaguarda.ROLE_PARCEIRO_ARQUIVO_LOCADORES_ATUALIZACAO],
  },
  {
    key: "803",
    label: "Contratos de locação",
    path: "/erp-contratos-locacao",
    parent: "800",
    roles: [RoleRetaguarda.ROLE_PARCEIRO_ARQUIVO_LOCADORES_ATUALIZACAO],
  },
];

function getMenusAutorizados(userRoles: string[]) {
  return menusRetaguarda.filter((menuRetaguarda) =>
    menuRetaguarda.roles.some((menuRole) => userRoles.includes(menuRole))
  );
}

function getKeysMenuAtivo(currentLocation: string) {
  const currentMenu = menusRetaguarda.find(
    (menu) => menu.path === currentLocation
  );

  return (
    currentMenu &&
    (currentMenu.parent
      ? [currentMenu.key.toString(), currentMenu.parent.toString()]
      : [currentMenu.key.toString()])
  );
}
export { getKeysMenuAtivo, getMenusAutorizados };

