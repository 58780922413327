import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, MenuProps, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getAuthoritiesUsuarioLogado } from "../../model/usuarioLogado";
import {
  getKeysMenuAtivo,
  getMenusAutorizados,
  MenuItemRetaguarda,
} from "./config";

type MenuItem = Required<MenuProps>["items"][number];

const SideBar = () => {
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const [itens, setItens] = useState<any[]>([]);
  const [keyMenuAtivo, setKeyMenuAtivo] = useState<string[]>([]);

  useEffect(() => {
    const itensMenu = getMenuItens();
    itensMenu && setItens(itensMenu);

    const menuCorrente = getKeysMenuAtivo(location.pathname);
    if (menuCorrente) {
      setKeyMenuAtivo([menuCorrente[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  function getMenuItemByMenuItemRetaguarda(
    menu: MenuItemRetaguarda,
    children?: MenuItem[]
  ): any {
    return {
      key: menu.key,
      label: menu.label,
      icon: menu.icon,
      onClick: () => {
        menu.path && history.push(menu.path);
      },
      children,
    };
  }

  function getMenuItens() {
    const userAuthorities = getAuthoritiesUsuarioLogado();

    if (Array.isArray(userAuthorities)) {
      const menusAutorizados = getMenusAutorizados(userAuthorities);
      const antdMenuItems = menusAutorizados
        .filter((menuPermitido) => !menuPermitido.parent)
        .map((menuPermitido) => {
          const submenus = menusAutorizados
            .filter((menu) => menu.parent === menuPermitido.key)
            .map((menu) => getMenuItemByMenuItemRetaguarda(menu));
          return getMenuItemByMenuItemRetaguarda(menuPermitido, submenus);
        });

      return antdMenuItems;
    }
  }

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      {collapsed ? (
        <MenuUnfoldOutlined
          className="trigger"
          style={{
            color: "#F2F2F2",
            backgroundColor: "#F2F2F2",
            width: "100%",
            borderBottom: "1px solid white",
            marginBottom: "1px",
          }}
          onClick={() => onCollapse(!collapsed)}
        >
          {" "}
        </MenuUnfoldOutlined>
      ) : (
        <div
          className="trigger"
          style={{
            color: "white",
            backgroundColor: "#F2F2F2",
            width: "100%",
            textAlign: "center",
            borderBottom: "1px solid white",
            marginBottom: "1px",
          }}
          onClick={() => onCollapse(!collapsed)}
        >
          <img
            src="/img/svg/Frame logo.svg"
            alt="Menu Expandido"
            width={"130px"}
            height={"35px"}
          />
        </div>
      )}

      <Menu
        mode="inline"
        defaultOpenKeys={["10", "100", "200", "300", "400", "500", "600", "800"]}
        selectedKeys={keyMenuAtivo}
        items={itens}
        style={{
          height: "85vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        className="custom-scrollbar"
      />

      <Row align="middle">
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            style: {
              color: "black",
              backgroundColor: "#F2F2F2",
              bottom: "0px",
              borderTop: "1px solid white",
              marginTop: "1px",
            },
            onClick: () => onCollapse(!collapsed),
          }
        )}
      </Row>
    </Sider>
  );
};

export default SideBar;
