import { ConfigProvider } from "antd";
import "antd/dist/antd.min.css";
import locale from "antd/lib/locale/pt_BR";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { CustomThemeProvider } from "./hooks/theme";
import Router from "./router";
import i18n from "./translation";

const App = () => (
  <CustomThemeProvider>
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Router />
        </I18nextProvider>
      </BrowserRouter>
    </ConfigProvider>
  </CustomThemeProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));
