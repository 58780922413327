import jwt_decode from "jwt-decode";
import { UsuarioLogadoProps } from "../common/types";

const usuarioLogado: UsuarioLogadoProps = {
  authorities: [],
};

const getAuthoritiesUsuarioLogado = (): string[] | undefined => {

  if (usuarioLogado.authorities?.length) {
    return usuarioLogado.authorities;
  } else {
    let jwtDecoded: any =
      jwt_decode(localStorage.getItem("tokenAuth") || "") || null;

      if (!jwtDecoded.azp || Date.now() > jwtDecoded.exp * 1000) {
        alert('Sua sessão expirou, necessário fazer o login novamente!');
        localStorage.removeItem('tokenAuth');
        window.location.href = '/';
      } else {
        usuarioLogado.authorities = Array.isArray(jwtDecoded.realm_access.roles)
          ? jwtDecoded.realm_access.roles
          : [jwtDecoded.realm_access.roles];
      }

    return usuarioLogado.authorities;
  }
};

function isUsuarioDeslogado() {
  return !usuarioLogado.authorities;
}

const isUsuarioCashGO = () => {
  return getAuthoritiesUsuarioLogado()?.includes("ROLE_USER_CASHGO");
};

const isUsuarioImobiliaria = () => {
  return getAuthoritiesUsuarioLogado()?.includes("ROLE_USER_IMOBILIARIA");
};

const isUsuarioProprietario = () => {
  return getAuthoritiesUsuarioLogado()?.includes("ROLE_USER_PROPRIETARIO");
};

const isUsuarioCreditoCashGO = () => {
  return getAuthoritiesUsuarioLogado()?.includes("ROLE_USER_CREDITO_CASHGO");
};

const isUsuarioFinanceiro = () => {
  return getAuthoritiesUsuarioLogado()?.includes("ROLE_USER_FINANCEIRO");
};

const isUsuarioComercialCashGO = () => {
  return getAuthoritiesUsuarioLogado()?.includes("ROLE_USER_COMERCIAL_CASHGO");
};

export {
  usuarioLogado,
  isUsuarioCashGO,
  isUsuarioCreditoCashGO,
  isUsuarioComercialCashGO,
  isUsuarioFinanceiro,
  isUsuarioDeslogado,
  isUsuarioImobiliaria,
  isUsuarioProprietario,
  getAuthoritiesUsuarioLogado,
};
