import { notification } from "antd";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { CriacaoDispositivoNotificacaoRequest } from "./common/types";
import { getEnvValue, isProduction } from "./common/utils/environmentUtils";
import { salvarDispositivo } from "./services/notificacao/notificacaoService";

let firebaseConfig;
let lastNotificationTimeStamp = 0;
if (isProduction()) {
  firebaseConfig = {
    apiKey: "AIzaSyBkBbOPKfxtbZKwC9Dl-k6l1MPewS0qqyc",
    authDomain: "retaguarda-notification.firebaseapp.com",
    projectId: "retaguarda-notification",
    storageBucket: "retaguarda-notification.appspot.com",
    messagingSenderId: "532675411133",
    appId: "1:532675411133:web:4a10285ae7c6f220418bd4"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCjwLzXF5zBiy_O-L00llZe364XsWFgkuE",
    authDomain: "retaguarda-notification-dev.firebaseapp.com",
    projectId: "retaguarda-notification-dev",
    storageBucket: "retaguarda-notification-dev.appspot.com",
    messagingSenderId: "353092255908",
    appId: "1:353092255908:web:8eaf632543f0ec24952631"
  };
}

const app = initializeApp(firebaseConfig);
let messaging: any;

type FirebaseConfigProps = {
  requestPermission: boolean;
  atualizarCountNotificacoes?: () => void;
};

const FirebaseConfig = ({
  requestPermission,
  atualizarCountNotificacoes,
}: FirebaseConfigProps) => {

  const criarDispositivo = (permissao: boolean, token?: string) => {
    const request: CriacaoDispositivoNotificacaoRequest = {
      permitido: permissao,
      tokenRegistro: token,
      userAgent: window.navigator.userAgent
    }

    salvarDispositivo(request)
      .then((response) => {
        console.log('Dispositivo registrado')
      })
      .catch((error) => {
        console.error("Não foi possível registrar o dispositivo para notificações.", error);
      })
  }

  const openNewTabLink = (link?: string) => {
    if (link) {
      const newTab = window.open(link, '_blank');
      newTab!.focus();
    }
  }

  const requestNotificationPermission = () => {
    Notification?.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          return getToken(messaging, { vapidKey: getEnvValue("REACT_FIREBASE_TOKEN") })
            .catch((err) => {
              console.log('Aceitou as notificações.');
            });
        }
      });
  }

  const requestNotificationPermissionWithouRegister = () => {
    const labelNaoPermitido = "nao_permitido";
    const userFirebaseToken = localStorage.getItem("userFirebaseToken");
    if (!userFirebaseToken || userFirebaseToken === 'nao_permitido') {
      Notification?.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            return getToken(messaging, { vapidKey: getEnvValue("REACT_FIREBASE_TOKEN") })
              .then((currentToken) => {
                criarDispositivo(true, currentToken);
                localStorage.setItem("userFirebaseToken", currentToken);
              }).catch((err) => {
                console.log('Erro ao buscar token do firebase.', err);
              });
          } else {
            if(!userFirebaseToken){
              localStorage.setItem("userFirebaseToken", labelNaoPermitido);
              criarDispositivo(false);
            }
          }
        });
    }
  }

  const onFirebaseMessageListener = () => {
    onMessage(messaging, (payload) => {
      notification.warning({
        message: payload?.notification?.title,
        description: payload?.notification?.body,
        style: { cursor: "pointer" },
        onClick: () => openNewTabLink(payload?.data?.link),
      });
    });
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.timeStamp !== lastNotificationTimeStamp && event.data &&
        event.data.messageType === 'push-received' && atualizarCountNotificacoes) {
        lastNotificationTimeStamp = event.timeStamp;
        atualizarCountNotificacoes();
      }
    });
  }

  useEffect(() => {
    (async () => {
      const hasFirebaseMessagingSupport = await isSupported();
      if (hasFirebaseMessagingSupport) {
        messaging = getMessaging(app);

        onFirebaseMessageListener();
        if (requestPermission) {
          requestNotificationPermission();
        } else {
          requestNotificationPermissionWithouRegister();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<></>);
};

export default FirebaseConfig;
