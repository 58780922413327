import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ProtectedRouteWithSidebar } from "../components/ProtectedRouteWithSidebar";
import Logout from "../pages/Logout";
import "../styles/app.less";
import { Styles } from "../styles/styles";
import routes from "./config";


const Router = () => {
  
  return (
    <Suspense fallback={null}>
      <Styles />
      <Switch>
        <Route
          key={"Login"}
          path={["/login"]}
          exact={true}
          component={lazy(() => import("../pages/Login"))}
        />
        <Route
          key={"RecuperarSenha"}
          path={["/recuperar-senha"]}
          exact={true}
          component={lazy(() => import("../pages/Login/RecuperarSenha"))}
        />
        <Route
          key={"Logout"}
          path={["/logout"]}
          exact={true}
          component={Logout}
        />

        {routes.map((routeItem) => {
          return (
            <ProtectedRouteWithSidebar
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}    
        <Redirect to="/login"/>
      </Switch>      
    </Suspense>
  );
};

export default Router;
